<template>
  <div>
    <top />
    <div class="back"></div>

    <div class="container">
      <div class="content">
        <div class="touxiang">
          <img :src="imageUrl(head_pic)" alt="" />
        </div>
        <div class="author-name">{{ nickname }}</div>
        <div style="margin-top: 16px">
          上传作品{{ counts != "" ? counts : 0 }}张
        </div>
        <div style="margin-top: 16px">粉丝数量 : {{ numberoffans }}</div>
        <div class="follow" v-if="follow == 'no'" @click="fllowDo(1)">
          关注+
        </div>
        <div class="follow" v-else @click="fllowDo(2)">已关注</div>
		
		<div class="tags-warp" v-if="tags_list.length>0">
			<div class="tags-title">热门标签：</div>
			<div class="tags-list">
				<div class="tags-item" :class="tags_index==item.id?'active':''" v-for="(item, index) in tags_list" :key="index" @click="totags(item.title,item.id)">{{item.title}}</div>
			</div>
		</div>
      </div>
    </div>

    <div class="search-box">
      <div class="search">
        <div class="left">
			<el-select
				class="select"
				multiple 
				v-model="format_checkeds"
				@change="changeformat"
				placeholder="选择格式"
			>
				<el-option
				  :label="itm"
				  :value="itm"
				  v-for="(itm, idx) in format_arr"
				  :key="idx"
				></el-option>
			</el-select>
          <el-select
            v-model="type"
            placeholder="请选择"
            class="select"
            @change="changes"
          >
            <!-- <el-option label="新款图案浏览" :value="1"> 新款图案浏览</el-option>
            <el-option label="包年下载浏览" :value="2">包年下载浏览 </el-option>
            <el-option label="原创爆款浏览" :value="20"
              >原创爆款浏览
            </el-option> -->
            <el-option
              :label="item.typename"
              :value="item.id"
              v-for="(item, index) in topCate"
              :key="index"
            ></el-option>
          </el-select>

          <el-select
            :placeholder="item.typename"
			class="select"
            v-for="(item, index) in arctype"
            :key="index"
            v-model="choose_type[index]"
            @change="change"
          >
            <el-option
              :label="itemm.typename"
              :value="itemm.id"
              v-for="(itemm, indexx) in item.son"
              :key="indexx"
            ></el-option>
          </el-select>
		  <el-select
		    class="select"
		    v-model="show_model"
		    @change="changemodel"
		  >
		    <el-option
		      label="图片模式"
		      value="1"
		    ></el-option>
		    <el-option
		      label="样衣模式"
		      value="2"
		    ></el-option>
		  </el-select>
        </div>

        <div class="right">
          <el-input placeholder="请输入内容" v-model="key_word" @input="search">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
      </div>
    </div>

    <list :list="list" :hot_limit="hot_limit" :hot_max="hot_max" :show_model="show_model"/>

    <div class="page-box">
      <div class="pages">
        <el-pagination
          :hide-on-single-page="count <= size ? true : false"
          @current-change="handleCurrentChange"
          :page-size="size"
          layout="total, prev, pager, next ,jumper"
          :total="count"
        >
        </el-pagination>
      </div>
    </div>
    <foot />
  </div>
</template>

<script>
import top from "../../components/common/header.vue";
import list from "../../components/open-list/open-list.vue";
import foot from "../../components/common/footer.vue";
import {
  authorDetail,
  fllowDo,
  checkOriginalAuth,
  IndexData,
} from "../../request/api";
export default {
  components: {
    top,
    list,
    foot,
  },

  data() {
    return {
      list: "",
      follow: "", //是否关注
      arctype: "", //分类
      type: 1, //所属区域
      choose_type: [], //选中的分类
      key_word: "", //搜索内容
      count: 1,
      counts: "",
      size: 20,
      page: 1,
      head_pic: "", //作者头像
      nickname: "", //作者名称
      users_id: "", //作者id
      userInfo: "",
      levelShow: false,
      thisType: 1,
      numberoffans: 0,
      topCate: "",
      hot_limit: "",
      hot_max: "",
	  tags_list:[],
	  tags_index:0,
	  tag_key:'',
	  show_model:'1',
	  format_checkeds:[],
	  format_arr:[]
    };
  },

  created() {
    this.getData();
    IndexData({}).then((res) => {
      this.topCate = res.data.topCate;
      console.log(this.topCate);
    });
  },
  methods: {
    open() {
      this.$alert("请购买原创vip浏览原创作品", "", {
        confirmButtonText: "确定",
        center: true,
      });
    },
	changemodel(val) {
	  this.show_model = val;
	},
    //获取数据
    getData() {
      authorDetail({
        author_id: this.$route.query.author_id,
        choose_type: this.type + "," + this.choose_type.toString(),
		formats:this.format_checkeds.toString(),
        key_word: this.key_word,
		tag_key:this.tag_key,
        type: this.type,
        page: this.page,
        size: this.size,
      }).then((res) => {
        // console.log(res);
        this.list = res.data.authorAbout;
		this.tags_list = res.data.tags_list;
        this.follow = res.data.follow;
        this.arctype = res.data.arctype;
        this.count = res.data.count;
        this.counts = res.data.counts;
        this.numberoffans = res.data.numberoffans;

        this.head_pic = res.data.authorInfo.head_pic;
        this.nickname = res.data.authorInfo.nickname;
        this.users_id = res.data.authorInfo.users_id;
		this.hot_limit = res.data.hot_limit;
		this.hot_max = res.data.hot_max;
		this.format_arr = res.data.format_arr;
      });
    },

    search() {
      this.getData();
    },
	totags(title,index){
		if(this.tags_index==index){
			this.tags_index = 0;
			this.tag_key = '';
		}else{
			this.tags_index = index;
			this.tag_key = title;
		}
	    this.page = 1;
	    this.getData();
	},

    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

    change(val) {
      this.page = 1;
      // console.log(this.choose_type);
      //  console.log(this.type)
      this.getData();
    },
	
	changeformat(){
		this.page = 1;
		this.getData();
	},

    changes(val) {
      console.log(val);
      if (val == 20) {
        checkOriginalAuth({}).then((res) => {
          console.log(res);
          if (res.code == 500) {
            this.open();
            this.type = this.thisType;
            return;
          }
          if (res.code == 200) {
            this.page = 1;
            this.thisType = val;
            this.getData();
            this.choose_type = [];
          }
        });
      } else {
        this.page = 1;
        this.thisType = val;
        this.getData();
        this.choose_type = [];
      }
    },

    fllowDo(type) {
      fllowDo({
        aid: this.users_id,
        type: type,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.back {
  width: 100%;
  min-width: 1282px;
  height: 300px;
  background: url("../../assets/author.png") no-repeat;
}

.container {
  width: 100%;
  min-width: 1280px;

  .content {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .touxiang {
      width: 133px;
      height: 133px;
      position: absolute;
      top: -66px;

      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%;
      }
    }

    .author-name {
      font-size: 24px;
      font-weight: 400;
      color: #000000;
      line-height: 24px;
      margin-top: 89px;
    }

    .follow {
      margin-top: 24px;
      width: 130px;
      height: 40px;
      background: #08243c;
      border-radius: 6px;

      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.search-box {
  width: 100%;
  min-width: 1280px;

  .search {
    width: 1280px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 43px 0;
	.left{
		width: calc(100% - 280px);
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
  }
}

.page-box {
  width: 100%;
  min-width: 1280px;
  overflow: hidden;

  .pages {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    padding-top: 52px;
    padding-bottom: 85px;
  }
}

/deep/.el-pager li {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}

/deep/.el-pager li.active {
  width: 32px;
  height: 32px;
  background: #08243c;
  border-radius: 50%;
}

/deep/.el-pager li.active {
  color: #ffffff;
}

/deep/.el-dialog,
/deep/.el-pager li {
  background: none;
}

/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: none;
}

/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 16px;
  line-height: 32px;
}

.tags-warp{
	display: flex;
	margin-top: 20px;
	justify-content: center;
	.tags-title{
		width:80px;
	}
	.tags-list{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		width:calc(100% - 80px);
		.tags-item{
			font-size: 14px;
			color: #08243c;
			border: 1px solid #08243c;
			padding: 3px 15px;
			border-radius: 20px;
			margin: 0 10px 10px 10px;
			cursor: pointer;
			font-size: 14px;
		}
		.tags-item.active{
			background-color: #08243c;
			color: #fff;
		}
	}
}
.select{
	width: 146px
}
</style>
