<template>
  <div class="box">
    <top />
    <div class="block">
      <el-carousel height="320px">
        <el-carousel-item v-for="item in banner" :key="item.aid">
          <a :href="`http://` + item.url">
            <img
              style="width: 100%"
              :src="item.litpic"
              alt=""
            />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="cate-box">
      <div class="cate">
		  <el-select
			class="select"
			multiple 
			v-model="format_checkeds"
			@change="changeformat"
			placeholder="选择格式"
		  >
			<el-option
			  :label="itm"
			  :value="itm"
			  v-for="(itm, idx) in format_arr"
			  :key="idx"
			></el-option>
		  </el-select>
        <el-select
          class="select"
          v-model="choose_type[index]"
          :placeholder="item.typename"
          v-for="(item, index) in arctype"
          :key="index"
          @change="change"
        >
          <el-option label="全部" value=""></el-option>
          <el-option
            :label="itemm.typename"
            :value="itemm.id"
            v-for="(itemm, indexx) in item.son"
            :key="indexx"
          ></el-option>
        </el-select>
		<el-select
		  class="select"
		  v-model="show_model"
		  @change="changemodel"
		>
		  <el-option
		    label="图片模式"
		    value="1"
		  ></el-option>
		  <el-option
		    label="样衣模式"
		    value="2"
		  ></el-option>
		</el-select>
      </div>
    </div>

    <list
      :list="list"
      :hot_limit="hot_limit"
      :hot_max="hot_max"
	  :show_model="show_model"
      v-if="list.length"
    />

    <el-empty description="正在搜索中" v-else></el-empty>

    <div class="page-box">
      <div class="pages">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="size"
          layout="total, prev, pager, next ,jumper"
          :total="count"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>

    <foot />
  </div>
</template>

<script>
import top from "../../components/common/header.vue";
import list from "../../components/open-list/open-list.vue";
import foot from "../../components/common/footer.vue";
import { openAreaData } from "../../request/api";
export default {
  components: {
    top,
    list,
    foot,
  },
  data() {
    return {
      list: "",
      count: 1,
      size: 20,
      page: 1,

      arctype: "", //分类
      choose_type: [],
      banner: [],
      hot_limit: "",
      hot_max: "",
	  show_model:'1',
	  format_checkeds:[],
	  format_arr:[]
	  
    };
  },

  created() {
    this.getData();
  },

  methods: {
    //获取数据
    getData() {
      openAreaData({
        type: this.$route.params.id,
        choose_type: this.choose_type.toString(),
		formats:this.format_checkeds.toString(),
        page: this.page,
        size: this.size,
      }).then((res) => {
        console.log(res);
        if (res.code == 301) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          this.page = 1;
        } else {
          this.list = res.data.archivesInfo;
          this.count = res.data.count;
          this.arctype = res.data.arctype;
          this.size = res.data.page_limit;
          this.banner = res.data.ad;
          this.hot_limit = res.data.hot_limit;
          this.hot_max = res.data.hot_max;
		  this.format_arr = res.data.format_arr;
        }
      });
    },

    //选项
    change(val) {
      this.page = 1;
      this.getData();
    },
	changemodel(val) {
	  this.show_model = val;
	},
	changeformat(){
		this.page = 1;
		this.getData();
	},
    //分页
    handleCurrentChange(val) {
      console.log(val);
      this.page = val;
      this.getData();
    },

    ok() {
      console.log(this.choose_type.toString());
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f6f6f6;
}

.cate-box {
  width: 100%;
  min-width: 1280px;
  .cate {
    width: 1280px;
    margin: 0 auto;
    padding: 43px 0;

    .select {
      margin-left: 63px;
      width: 146px;
    }

    .select:first-child {
      margin-left: 0;
    }
  }
}

.page-box {
  width: 100%;
  min-width: 1280px;
  overflow: hidden;
  .pages {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    padding-top: 52px;
    padding-bottom: 85px;
  }
}

/deep/.el-pager li {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}

/deep/.el-pager li.active {
  width: 32px;
  height: 32px;
  background: #08243c;
  border-radius: 50%;
}

/deep/.el-pager li.active {
  color: #ffffff;
}

/deep/.el-dialog,
/deep/.el-pager li {
  background: none;
}

/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: none;
}

/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 16px;
  line-height: 32px;
}
.block {
  width: 1280px;
  margin: 0 auto;
}

</style>